import {
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  asciiCompare,
  getIndicesBetween,
} from "../fractionalIndexing.js";
import { CellId } from "../idTypeBrands.js";

export interface SortableCell {
  id: CellId;
  order: string;
  parentCellId?: CellId | null;
}

function sortableCellCompareFn<T extends SortableCell>(a: T, b: T): number {
  return asciiCompare(a.order, b.order);
}

export interface SortedCellNode<T extends SortableCell> {
  cell: T;
  children?: SortedCellNode<T>[];
}

/**
 * Put a list of cells into sorted order.
 *
 * This accounts for parent cells that can affect ordering so the result is tree like.
 * To get a flattened list, use {@link flattenSortedCells}
 */
export function sortCells<T extends SortableCell>(
  cells: readonly T[],
): SortedCellNode<T>[] {
  const cellIdToCell = cells.reduce<Record<CellId, T>>((acc, cell) => {
    acc[cell.id] = cell;
    return acc;
  }, {});

  const cellIdToChildren = cells.reduce<Record<CellId, T[] | undefined>>(
    (acc, cell) => {
      if (cell.parentCellId != null) {
        const prevChildren = (acc[cell.parentCellId] ??= []);
        prevChildren.push(cell);
      }
      return acc;
    },
    {},
  );

  const rootCells = cells.filter(
    (cell) =>
      cell.parentCellId == null || cellIdToCell[cell.parentCellId] == null,
  );

  // now we do a recursive graph search starting with cells with a depth of zero
  return recursiveSortCells(rootCells, cellIdToChildren);
}

/**
 * Helper function to actually assemble the 'tree' of sorted cells
 */
function recursiveSortCells<T extends SortableCell>(
  cells: T[],
  cellIdToChildren: Record<CellId, T[] | undefined>,
): SortedCellNode<T>[] {
  return cells.toSorted(sortableCellCompareFn).map((cell) => {
    const rawChildren = cellIdToChildren[cell.id];

    const children =
      rawChildren != null
        ? recursiveSortCells(rawChildren, cellIdToChildren)
        : undefined;

    return { cell, children };
  });
}

/**
 * Flatten out a collection of sorted cells from its tree like structure
 * into a simple sorted array.
 *
 * If you want a list of these cells with a 'corrected' order use {@link interpolateSortedCellOrders}
 */
export function flattenSortedCells<T extends SortableCell>(
  sortedCells: readonly SortedCellNode<T>[],
): T[] {
  return sortedCells.reduce<T[]>((acc, node) => {
    acc.push(node.cell);
    if (node.children != null) {
      acc.push(...flattenSortedCells(node.children));
    }
    return acc;
  }, []);
}

/**
 * Given a collection of sorted cells, provide interpolated orders
 * such that children can be sorted globally regardless of depth
 */
export function interpolateSortedCellOrders<T extends SortableCell>(
  sortedCells: readonly SortedCellNode<T>[],
): Record<CellId, string> {
  const interpolatedOrders: Record<CellId, string> = {};

  for (const [idx, node] of sortedCells.entries()) {
    // at the top level, we can just reuse the existing cell order
    interpolatedOrders[node.cell.id] = node.cell.order;

    if (node.children != null) {
      const newPrevOrder = node.cell.order;
      const newNextOrder =
        sortedCells[idx + 1]?.cell.order ?? FRACTIONAL_INDEX_END;

      recursiveInterpolateSortedCellOrders({
        sortedCells: node.children,
        previousOrder: newPrevOrder,
        nextOrder: newNextOrder,
        interpolatedOrders,
      });
    }
  }

  return interpolatedOrders;
}

function recursiveInterpolateSortedCellOrders<T extends SortableCell>({
  interpolatedOrders,
  nextOrder,
  previousOrder,
  sortedCells,
}: {
  sortedCells: readonly SortedCellNode<T>[];
  previousOrder: string | typeof FRACTIONAL_INDEX_START;
  nextOrder: string | typeof FRACTIONAL_INDEX_END;
  interpolatedOrders: Record<CellId, string>;
}): void {
  const orderRange = getIndicesBetween({
    start: previousOrder,
    end: nextOrder,
    count: sortedCells.length,
  });

  for (const [idx, node] of sortedCells.entries()) {
    interpolatedOrders[node.cell.id] = orderRange[idx]!;
  }

  for (const [idx, node] of sortedCells.entries()) {
    if (node.children != null) {
      const newPrevOrder = interpolatedOrders[node.cell.id]!;

      const newNextCell = sortedCells[idx + 1]?.cell;
      const newNextOrder =
        newNextCell !== undefined
          ? interpolatedOrders[newNextCell.id] ?? FRACTIONAL_INDEX_END
          : FRACTIONAL_INDEX_END;

      recursiveInterpolateSortedCellOrders({
        sortedCells: node.children,
        previousOrder: newPrevOrder,
        nextOrder: newNextOrder,
        interpolatedOrders,
      });
    }
  }
}

/**
 * If a cell does not match `predicate` remove it and all
 * its children from the tree
 */
export function filterSortedCells<T extends SortableCell>(
  sortedCells: readonly SortedCellNode<T>[],
  predicate: (value: T) => unknown,
): SortedCellNode<T>[] {
  return sortedCells
    .filter((node) => predicate(node.cell))
    .map((node) => ({
      cell: node.cell,
      children:
        node.children != null
          ? filterSortedCells(node.children, predicate)
          : undefined,
    }));
}

/**
 * If a cell does not match `predicate`, prune its children
 * but keep it in the list.
 */
export function filterSortedCellsChildren<T extends SortableCell>(
  sortedCells: readonly SortedCellNode<T>[],
  predicate: (value: T) => unknown,
): SortedCellNode<T>[] {
  return sortedCells.map((node) => ({
    cell: node.cell,
    children:
      node.children != null && predicate(node.cell)
        ? filterSortedCellsChildren(node.children, predicate)
        : undefined,
  }));
}
